<template>
    <div class="row">
      <div class="col-12">
        <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden mb-4">
            <ul class="nav nav-tabs xs-p-4 d-flex align-items-center justify-content-around product-info-tab border-bottom-0">
                <li class="list-inline-item" :class="{'active' : $route.name == 'EventAdmin'}">
                    <router-link :to="{name: 'EventAdmin'}" class="fw-700 pb-sm-4 pt-sm-4 xs-mb-2 font-xssss text-grey-500 ls-3 d-inline-block text-uppercase" :class="{'active' : $route.name == 'EventAdmin'}">Event</router-link>
                </li>
                <li class="list-inline-item" :class="{'active' : $route.name == 'EventCategoryAdmin'}">
                    <router-link :to="{name: 'EventCategoryAdmin'}" class="fw-700 pb-sm-4 pt-sm-4 xs-mb-2 font-xssss text-grey-500 ls-3 d-inline-block text-uppercase" :class="{'active' : $route.name == 'EventCategoryAdmin'}">Category</router-link>
                </li>
                <li class="list-inline-item" :class="{ 'active': $route.name == 'EventTestimonyAdmin' }">
                        <router-link :to="{ name: 'EventTestimonyAdmin' }"
                            class="fw-700 pb-sm-4 pt-sm-4 xs-mb-2 font-xssss text-grey-500 ls-3 d-inline-block text-uppercase"
                            :class="{ 'active': $route.name == 'EventTestimonyAdmin' }">Testimony</router-link>
                    </li>
            </ul>
        </div>
      </div>

      <div class="col-12">
        <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-4">
          <div class="row justify-content-center">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
              <router-link :to="{ name: 'EventCategoryCreateAdmin' }" class="btn btn-current float-right mt-3">
                <i class="fas fa-plus"></i>
                Create New
              </router-link>
              <h1 class="mt-4 font-weight-bolder">
                <i class="fas fa-ribbon mr-3"></i>
                Category
              </h1>
              <hr>
              <div class="row justify-content-center d-flex" v-if="dataExist">
                <div  class="col-xl-12 col-lg-12">
                    <input type="text" class="form-control" placeholder="Search..." v-model="tableFilter">
                </div>
                <div class="col-xl-12 col-lg-12 text-center py-3">
                    <div class="table-responsive mw-100 px-1">
                        <datatable :class="'table table-hover table-bordered table-sm w-100'" :columns="columns" :filter="tableFilter" :data="data" :page="1" :perPage="10">
                            <template name="default" slot-scope="{ row, index }">
                                <tr v-if="row">
                                    <td class="text-center">{{ index + 1 }}</td>
                                    <td class="text-center">{{ row.name }}</td>
                                    <td class="text-center">
                                        <a href="javascript:void(0)" @click="deleteAlert(row.id)"><i class="ti-trash font-xs text-grey-500"></i></a>
                                    </td>
                                </tr>
                                <tr v-else>
                                    <td colspan="8">Nothing to see here</td>
                                </tr>
                            </template>
                        </datatable>
                    </div>
                    <datatable-pager v-model="page" type="abbreviated"></datatable-pager>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 text-center py-3">
                  <div class="my-3" v-show="loadData">
                    <div v-for="i in 6" :key="i" class="spinner-grow text-warning mx-3" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                  <img :src="'/images/empty.png'" v-show="dataNotExist" alt="" width="300">
                  <h1 class="text-muted" v-show="dataNotExist">Data don't exist</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>

<script>
import axios from 'axios'
export default {
    data() {
        return {
            dataExist: false,
            dataNotExist: false,
            loadData: true,
            media: process.env.VUE_APP_URL_CLOUD,
            data: [],
            page: 1,
            tableFilter: '',
            columns: [
                { label: 'No', field: 'id', headerClass: 'border-0 bg-current text-white py-3', class: 'product-thumbnail text-center', filterable: false, sortable: false },
                { label: 'Name', field: 'name', headerClass: 'border-0 bg-current text-white py-3', class: 'product-price-wrapper text-center' },
                { label: 'Option', headerClass: 'border-0 bg-current text-white py-3', class: 'product-price-wrapper text-center', filterable: false, sortable: false },
            ]
        }
    },
    created() {
        this.getEventCategory()
    },
    methods: {
        async getEventCategory() {
            await axios.get(`${process.env.VUE_APP_URL_API}/admin/events/category`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.token}`
                },
            }).then(res => {
                this.loadData = false
                this.dataExist = true
                this.data = res.data.data
            })
        },
        deleteAlert(id) {
            this.$swal({
                title: 'Are you sure?',
                text: 'You can\'t revert your action',
                showCancelButton: true,
                confirmButtonText: 'Yes Delete it!',
                cancelButtonText: 'No, Keep it!',
                showCloseButton: true,
                showLoaderOnConfirm: true,
                preConfirm: async () => {
                    this.deleteArticle(id)
                },
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$swal({
                        toast: true,
                        title: 'Event Category',
                        text: 'Your data has been deleted !',
                        icon: 'success',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                } else {
                    this.$swal({
                        toast: true,
                        title: 'Cancelled',
                        text: 'Your data is still intact',
                        icon: 'info',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            })
        },
        async deleteArticle(id) {
            var data = {
                id: id,
                _method: 'delete'
            }
            await axios.post(`${process.env.VUE_APP_URL_API}/admin/events/category`, data, {
                headers: {
                    'Authorization': `Bearer ${localStorage.token}`
                },
            }).then(() => {
                this.getEventCategory()
            }).catch(() => {
                this.getEventCategory()
            })
        },
    },
}
</script>
